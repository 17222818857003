var currentImageIndex = 0;
var images = Array.from(document.querySelectorAll(".image-modal-img")).map(
  function (img) {
    return img.src;
  }
);

function openModal(src) {
  var modal = document.getElementById("imageModal");
  modal.style.display = "block";
  document.getElementById("imageModalContent").src = src;
  currentImageIndex = images.indexOf(src);
}

function closeModal() {
  document.getElementById("imageModal").style.display = "none";
}

function showNextImage() {
  currentImageIndex = (currentImageIndex + 1) % images.length;
  document.getElementById("imageModalContent").src = images[currentImageIndex];
}

// Close modal if clicked outside of the image
window.onclick = function (event) {
  var modal = document.getElementById("imageModal");
  if (event.target === modal) {
    closeModal();
  }
};

// Add click event to gallery images
document.querySelectorAll(".image-modal-img").forEach(function (img) {
  img.onclick = function () {
    openModal(img.src);
  };
});

// Add click event to modal image
if (document.getElementById("imageModalContent")) {
  document.getElementById("imageModalContent").onclick = showNextImage;
}
