(function () {
    'use strict';

    var recommendation = document.querySelector('.recommendation');
    var isVisible = false;

    if (recommendation) {
        // Back to top button
        var goBackToTop = recommendation.querySelector('.message > a');
        goBackToTop.addEventListener('click', function () {
            scrollToTop();
            return false;
        });

        // // Hide
        // document.addEventListener('stillReading', function (elem) {
        //     if (isVisible) {
        //         recommendation.style.bottom = '-100%';
        //         isVisible = false;
        //     }
        // }, false);

        // // Show
        // document.addEventListener('finishedReading', function (elem) {
        //     if (!isVisible) {
        //         recommendation.style.bottom = '0%';
        //         isVisible = true;
        //     }
        // }, false);

        // Set up the IntersectionObserver
        var observer = new IntersectionObserver(function (entries) {
            if (entries[0].isIntersecting && !isVisible) {
                // Show recommendation when user scrolls to the bottom
                recommendation.style.bottom = '0%';
                isVisible = true;
            }
        }, { threshold: 0 });

        // Start observing the placeholder element at the bottom of the page
        observer.observe(document.getElementById('recommendation-start'));

        // Set up the IntersectionObserver
        var observer = new IntersectionObserver(function (entries) {
            if (entries[0].isIntersecting && isVisible) {
                // Hide recommendation when user scrolls up
                recommendation.style.bottom = '-100%';
                isVisible = false;
            }
        }, { threshold: 0 });

        // Start observing the placeholder element at the bottom of the page
        observer.observe(document.getElementById('recommendation-end'));
    }

    var timeOut;
    function scrollToTop() {
        if (document.body.scrollTop != 0 || document.documentElement.scrollTop != 0) {
            window.scrollBy(0, -50);
            timeOut = setTimeout(scrollToTop, 10);
        }
        else clearTimeout(timeOut);
    }
})();
